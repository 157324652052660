import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import {
  Button,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
} from "reactstrap";
import { Avatar } from "antd";
// JS plugin that hides/shows header component on scroll
import Headroom from "headroom.js";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import logo_img from "../../assets/imgs/brand/logo.png";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseClasses: "",
      collapseOpen: false,
    };

    this.userListRef = React.createRef();
  }

  componentDidMount() {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    headroom.init();
    document.addEventListener("click", (e) => {
      if (!this.userListRef?.current?.contains(e.target)) this.onExiting();
    });
  }
  componentWillUnmount() {
    document.removeEventListener("click", () => this.onExiting());
  }

  onExiting = () => {
    this.setState({
      collapseClasses: "collapsing-out",
    });
  };

  onExited = () => {
    this.setState({
      collapseClasses: "",
    });
  };
  logout = () => {
    this.props.logout();
  };
  render() {
    const { isHome, isLogin, name, isLoginP, isSignupP } = this.props;
    const landingNav = (
      <Nav className="align-items-lg-center mr-lg-auto" navbar>
        <NavItem>
          <NavLink>
            <ScrollLink activeClass="active" to="home" spy={true} smooth={true}>
              Home
            </ScrollLink>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink>
            <ScrollLink
              activeClass="active"
              to="our-service"
              spy={true}
              smooth={true}
            >
              Our Service
            </ScrollLink>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink>
            <ScrollLink
              activeClass="active"
              to="our-projects"
              spy={true}
              smooth={true}
            >
              Our Projects
            </ScrollLink>
          </NavLink>
        </NavItem>
        <NavLink>
          <ScrollLink
            activeClass="active"
            to="contact-us"
            spy={true}
            smooth={true}
          >
            Contact Us
          </ScrollLink>
        </NavLink>
      </Nav>
    );

    return (
      <header className="header-global">
        <Navbar
          className={`navbar-main navbar-transparent navbar-dark bg-darker headroom ${
            isHome ? "" : "headroom--unpinned-home"
          }`}
          expand="lg"
          id="navbar-main"
        >
          <Container>
            <NavbarBrand className="mr-lg-5" to="/" tag={Link}>
              <img alt="NVP" src={logo_img} />
            </NavbarBrand>
            <button className="navbar-toggler" id="navbar_global">
              <span className="navbar-toggler-icon" />
            </button>
            <UncontrolledCollapse
              toggler="#navbar_global"
              navbar
              className={this.state.collapseClasses}
              onExiting={this.onExiting}
              onExited={this.onExited}
            >
              {true && isHome ? landingNav : null}
              <Nav
                ref={this.userListRef}
                className="align-items-lg-center ml-lg-auto"
                navbar
              >
                {/* <NavItem className="d-lg-block ml-lg-4"> */}
                {!isLogin ? (
                  isSignupP ? (
                    <NavItem className="d-lg-block ml-lg-4">
                      <Button
                        className="text-primary underline"
                        color="link"
                        outline="true"
                        href="/sign-in"
                      >
                        <span className="text-capitalize nav-link-inner--text">
                          Already a Member?
                        </span>
                        <span className="btn-inner--icon ml-1">
                          <FontAwesomeIcon icon={faArrowRight} />
                        </span>
                      </Button>
                    </NavItem>
                  ) : (
                    !isLoginP && (
                      <NavItem className="d-lg-block ml-lg-4">
                        <Button
                          className="text-primary"
                          color="link"
                          outline="true"
                          href="/sign-in"
                        >
                          <span className="text-capitalize nav-link-inner--text">
                            Sign In
                          </span>
                        </Button>
                        <Button
                          className="btn btn-md"
                          color="primary"
                          outline="true"
                          href="/sign-up"
                        >
                          <span className="text-capitalize nav-link-inner--text">
                            Sign Up
                          </span>
                        </Button>
                      </NavItem>
                    )
                  )
                ) : (
                  <NavItem className="d-lg-block ml-lg-4">
                    <div className="nav-buttons-list">
                      <span
                        style={{ order: 2, cursor: "pointer" }}
                        onClick={this.logout}
                        className=" mr-4"
                        outline="true"
                        color="primary"
                      >
                        Log out
                      </span>
                      <Link style={{ color: "white" }} to="/profile">
                        <Avatar
                          size="large"
                          style={{
                            backgroundColor: "#5e72e4",
                            cursor: "pointer",
                          }}
                        >
                          {name}
                        </Avatar>
                      </Link>
                    </div>
                  </NavItem>
                )}
                {/* </NavItem> */}
              </Nav>
            </UncontrolledCollapse>
          </Container>
        </Navbar>
      </header>
    );
  }
}

export default Header;
